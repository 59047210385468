import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { UpdateUserEmailRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useUpdateEmail = () => {
  const { user, accessToken, refetch } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: update, isLoading } = useMutation(
    ["users/email"],
    (data: UpdateUserEmailRequest) => api.updateUserEmail(data),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    email: user.email,
    update,
    isMutating: isLoading,
  };
};
