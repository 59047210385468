import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useDeactivate = () => {
  const { accessToken, signOut } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: deactivate, isLoading } = useMutation(
    ["users/deactivate"],
    () => api.deleteMe(),
    {
      onSuccess: () => {
        signOut();
      },
    },
  );

  return {
    deactivate,
    isLoading,
  };
};
