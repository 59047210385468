import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useWebAPI } from "shared/services/api";

type SessionOmniauthValue = {
  provider: "twitter";
  info: {
    email?: string;
    name?: string;
    nickname?: string;
    image?: string;
    description?: string;
  };
};

export const useSessionOmniauthValue = () => {
  const api = useWebAPI();
  const [provider, setProvider] = useState<SessionOmniauthValue["provider"]>();
  const [info, setInfo] = useState<SessionOmniauthValue["info"]>();

  const { isLoading } = useQuery(
    ["users/sessions/omniauth"],
    () => api.getUserSessionOmniauthValue({ credentials: "include" }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: async ({ data }) => {
        setProvider(data.provider);
        setInfo(data.info);
      },
    },
  );

  return {
    provider,
    info,
    isLoading,
  };
};
