import { useState, useCallback } from "react";

type UseBooleanStateType = (initialState?: boolean) => [boolean, () => void, () => void];

export const useBooleanState: UseBooleanStateType = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const setTrue = () => {
    setValue(true);
  };

  const setFalse = () => {
    setValue(false);
  };

  return [value, setTrue, setFalse];
};

type UseBooleanToggleStateType = (initialState?: boolean) => [boolean, () => void];

export const useBooleanToggleState: UseBooleanToggleStateType = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value]);

  return [value, toggle];
};
