import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { PaymentMethod } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { CreateUserPaymentMethodRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const usePaymentMethods = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null);

  const { refetch, isLoading } = useQuery(["users/usePaymentMethods"], () => api.getUserPaymentMethods(), {
    onSuccess: ({ data }) => {
      setPaymentMethods(data.paymentMethods.map((pm) => new PaymentMethod(pm)));
      setDefaultPaymentMethod(data.defaultPaymentMethod ? new PaymentMethod(data.defaultPaymentMethod) : null);
    },
  });

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["users/usePaymentMethods/create"],
    (params: CreateUserPaymentMethodRequest) => api.createUserPaymentMethod(params),
    {
      onSuccess: () => {
        refetch();
      }
    },
  );

  const { mutate: destroy } = useMutation(
    (id: string) => api.deleteUserPaymentMethod(id),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    paymentMethods,
    defaultPaymentMethod,
    create,
    destroy,
    isLoading: isLoading,
    isMutating: isCreateLoading,
  };
};
