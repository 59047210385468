import { Dialog } from "@headlessui/react";

import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

export type BottomModalProps = {
  open: boolean;
  onClose: () => void;
};

export const BottomModal: CFC<BottomModalProps> = ({
  open,
  onClose,
  className,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/40" />
      <div className="fixed inset-x-0 bottom-0 flex h-max">
        <Dialog.Panel className={twClassNames("w-full md:max-w-6xl md:mx-auto rounded-t-lg bg-white", className)}>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
