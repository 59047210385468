import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { UpdateUserPasswordRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useUpdatePassword = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: update, isLoading } = useMutation(
    ["users/password"],
    (data: UpdateUserPasswordRequest) => api.updateUserPassword(data),
  );

  return {
    update,
    isMutating: isLoading,
  };
};
