import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Topic } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

type MeId = "me";
type UserId = MeId | string;

type Params = {
  closed?: boolean;
  waitingBestProposals?: boolean;
};

export const useUserTopics = (userId: UserId, params: Params = {}) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const [topics, setTopics] = useState<Topic[]>([]);

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["users/topics", userId, params.closed, params.waitingBestProposals],
    ({ pageParam }) => api.getUserTopics(userId, pageParam || 1, params.closed, params.waitingBestProposals),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setTopics(pages.flatMap(({ data: { topics } }) => topics.map((t) => new Topic(t))));
      },
    },
  );

  return {
    topics,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
