import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { UpdateUserProfileRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useUpdateProfile = () => {
  const { accessToken, refetch } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: update, isLoading } = useMutation(
    ["users/profile/update"],
    (data: UpdateUserProfileRequest) => api.updateUserProfile(data),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    update,
    isMutating: isLoading,
  };
};
