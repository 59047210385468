import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { BankAccount } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useBankAccounts = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [defaultBankAccount, setDefaultBankAccount] = useState<BankAccount | null>(null);

  const { isLoading } = useQuery(
    ["users/bank_accounts"],
    () => api.getUserBankAccounts(),
    {
      onSuccess: ({ data }) => {
        setBankAccounts(data.bankAccounts.map((ba) => new BankAccount(ba)));
      }
    },
  );

  useEffect(() => {
    if (bankAccounts.length > 0) {
      setDefaultBankAccount(bankAccounts.find((ba) => ba.isAvailable()) ?? bankAccounts[0]);
    }
  }, [bankAccounts]);

  return {
    bankAccounts,
    defaultBankAccount,
    isLoading,
  };
};
