import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { SignInUserRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useSignIn = () => {
  const api = useWebAPI();
  const { signIn } = useAuthContext();
  const [isSignedInLoading, setIsSignedInLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const { mutateAsync, isLoading } = useMutation(
    ["users/signIn"],
    (data: SignInUserRequest) => api.signInUser(data),
    {
      onSuccess: async ({ data: { user, token } }) => {
        setIsSignedInLoading(true);
        signIn(new UserMe(user), new Token(token));
        setIsSignedIn(true);
        setIsSignedInLoading(false);
      },
    },
  );

  return {
    signIn: mutateAsync,
    isSignedInLoading,
    isLoading: isLoading || isSignedInLoading,
    isSignedIn,
  };
};
