import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { ConnectUserProviderRequest, DisconnectUserProviderRequest } from "shared/services/api/web";
import { isAPIError } from "shared/types";

import { useAuthContext } from "~/features/auth";

export enum SignConnectErrorType {
  Unknown = "unknown",
  NotFound = "not_found",
  AlreadyConnected = "already_connected",
  InvalidRecord = "invalid_record",
}

export const useSignConnect = () => {
  const { accessToken, signIn } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [errorType, setErrorType] = useState<SignConnectErrorType | null>(null);

  const { mutateAsync: connect, isLoading: isConnectLoading, isError, error } = useMutation(
    ["users/signConnect"],
    (data?: ConnectUserProviderRequest) => api.connectUserProvider(data, { credentials: "include" }),
    {
      onSuccess: async ({ data: { user, token } }) => {
        signIn(new UserMe(user), new Token(token));
      },
      onError: (e) => {
        if (isAPIError(e)) {
          if (e.response.status === 404) {
            setErrorType(SignConnectErrorType.NotFound);
          } else if (e.response.status === 409) {
            setErrorType(SignConnectErrorType.AlreadyConnected);
          } else if (e.response.status === 400) {
            setErrorType(SignConnectErrorType.InvalidRecord);
          } else {
            setErrorType(SignConnectErrorType.Unknown);
          }
        }
      },
    },
  );

  const { mutateAsync: disconnect, isLoading: isDisconnectLoading } = useMutation(
    ["users/signDisconnect"],
    (provider: DisconnectUserProviderRequest["provider"]) => api.disconnectUserProvider(provider),
  );

  return {
    connect,
    disconnect,
    error,
    errorType,
    isConnectLoading,
    isDisconnectLoading,
    isMutating: isConnectLoading || isDisconnectLoading,
    isError,
  };
};
