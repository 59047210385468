import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { SignUpUserRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useSignUp = () => {
  const api = useWebAPI();
  const { signIn } = useAuthContext();
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const { mutateAsync, isLoading } = useMutation(
    ["users/signUp"],
    (data: SignUpUserRequest) => api.signUpUser(data),
    {
      onSuccess: async ({ data: { user, token } }) => {
        setIsSignUpLoading(true);
        signIn(new UserMe(user), new Token(token));
        setIsSignUpLoading(false);
      },
    },
  );

  return {
    signUp: mutateAsync,
    isSignUpLoading,
    isLoading: isLoading || isSignUpLoading,
  };
};
