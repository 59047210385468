import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { ResetPasswordRequest, SendResetPasswordMailRequest } from "shared/services/api/web";

export const usePasswordReset = () => {
  const api = useWebAPI();

  const { mutateAsync: sendResetMail, isLoading: isSendLoading } = useMutation(
    ["users/password/new"],
    (data: SendResetPasswordMailRequest) => api.sendResetPasswordMail(data),
  );

  const { mutateAsync: reset, isLoading: isResetLoading } = useMutation(
    ["users/password/edit"],
    (data: ResetPasswordRequest) => api.resetPassword(data),
  );

  return {
    sendResetMail,
    reset,
    isSendLoading,
    isResetLoading,
    isMutating: isSendLoading || isResetLoading,
  };
};
