"use client";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";
import { toast } from "react-hot-toast";
import { FaSquareXTwitter } from "react-icons/fa6";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { BFC } from "shared/types";

import { ConfirmModal } from "~/components/elements";
import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { useSignConnect } from "../../hooks";

type Props = {
  failedMessage?: string;
};

export const ProvidersPage: BFC<Props> = ({
  failedMessage,
}) => {
  const router = useRouter();
  const { user, isLoading, refetch } = useAuthContext();
  const { disconnect } = useSignConnect();
  const [modalShown, showModal, hideModal] = useBooleanState(false);
  const connectedTwitter = user.getProviders().some((provider) => provider.isTwitter());

  const connectToTwitterHandler = useCallback(() => {
    if (connectedTwitter) {
      showModal();
    } else {
      router.push(routes.USERS_AUTH_WITH_TWITTER({ return_to: routes.MYPAGE_PROVIDERS }));
    }
  }, [connectedTwitter]);

  const disconnectFromTwitterHandler = useCallback(async () => {
    await disconnect("twitter");
    refetch();
    hideModal();
  }, [disconnect, refetch]);

  const connectedClassNames = useCallback((connected: boolean) => classNames({
    "bg-primary-100 border-primary": connected,
  }), []);

  const onBackClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  useEffect(() => {
    if (failedMessage) {
      toast.error(failedMessage);
    }
  }, [failedMessage]);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ColorRing />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onBackClick} />
          アカウント連携
        </h1>
      </div>

      <div className="bg-white">
        <h2 className="border-b p-4 font-bold">Xアカウント連携</h2>
        <div className="p-4">
          <Button large block className={classNames("flex gap-2", connectedClassNames(connectedTwitter))} onClick={connectToTwitterHandler}>
            <FaSquareXTwitter size={20} />
            {connectedTwitter ? "Xアカウント連携済み" : "Xアカウント連携"}
          </Button>
        </div>
      </div>

      <ConfirmModal
        open={modalShown}
        title="Xアカウント連携解除"
        description="Xアカウント連携を解除しますか？"
        onConfirm={disconnectFromTwitterHandler}
        onCancel={hideModal}
      />
    </div>
  );
};
