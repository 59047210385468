import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { ConfirmUserEmailRequest, SendConfirmUserEmailRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useEmailConfirmation = () => {
  const { accessToken, refetch } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: confirmEmail, isLoading: isConfirmEmailLoading } = useMutation(
    ["users/confirmations/email"],
    (data: ConfirmUserEmailRequest) => api.confirmUserEmail(data),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { mutateAsync: sendConfirmationMail, isLoading: isSendConfirmationEmailLoading } = useMutation(
    ["users/confirmations/email/send"],
    (data: SendConfirmUserEmailRequest) => api.sendConfirmUserEmail(data),
  );

  return {
    confirmEmail,
    sendConfirmationMail,
    isConfirmEmailLoading,
    isSendConfirmationEmailLoading,
    isFetching: isConfirmEmailLoading || isSendConfirmationEmailLoading,
  };
};
