import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { User } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

type Params = {
  keyword?: string;
};

export const useSearchUsers = (params: Params = {}) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const [users, setUsers] = useState<User[]>([]);

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["users/search", params],
    ({ pageParam }) => api.searchUsers(pageParam || 1, params.keyword),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setUsers(pages.flatMap(({ data: { users } }) => users.map((d) => new User(d))));
      },
    },
  );

  return {
    users,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
