import { Dialog } from "@headlessui/react";

import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SimpleModal: CFC<Props> = ({
  open,
  onClose,
  className,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/40" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className={twClassNames("h-max w-full max-w-md rounded-lg bg-white", className)}>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
